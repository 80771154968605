<template>
<div class="mx-auto">
    <div class="">
        <div>
            <center>
                <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
                    <div class="row">
                        <center>
                            <p class="lg:text-3xl lg:text-3xl lg:text-3xl lg:text-4xl xl:text-5xl title" style="margin-top:40px;">Bienvenido a FONCABSA</p>
                        </center>
                    </div>
                </div>
                <div class="flex-1 bg-gray-0 px-0 py-1 m-1">
                    <div class="row">
                        <center>
                            <p class="md:text-xl lg:text-1xl lg:text-2xl lg:text-3xl xl:text-4xl subTitle">
                                Panel de Administración
                            </p>
                        </center>
                    </div>
                </div>
                <div class="flex-1 bg-gray-0 px-0 py-0 m-0">
                    <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lx:grid-cols-3">
                        <div class="row">
                            <center>
                            </center>
                        </div>
                        <div class="row">
                            <center>
                            </center>
                        </div>
                        <div class="row">
                            <center>
                            </center>
                        </div>
                    </div>
                </div>
            </center>
            <br><br><br>
            <br><br><br>
            <br><br><br>
            <br><br><br>
            <br><br><br>
        </div>
    </div>
    <!--<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">-->
    <!--<link href="../../node_modules/tailwindcss/dist/tailwind.min.css" rel="stylesheet"> -->
</div>
</template>



<script>
import('@/assets/css/stylesheet.css')

import MenuLateral from '@/components/LateralMenu.vue';
import {useRouter} from "vue-router";
export default {
    name: 'PaginaPrincipal',
    components: {
        MenuLateral
    },
    setup(){
        const router = useRouter();
        return { router }
    },
    props: {
        msg: String
    },
    mounted() {
    },
    beforeMount() {
    },
    methods:{   
        Logout(){
            sessionStorage.clear();
            this.router.push({path:"/"});
            //localStorage.clear();
            // window.location.href = "/"
        }
    }
}
</script>

<style>
#panel{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
</style>